<template>
  <b-container fluid>
    <b-row>
      <b-col sm="3">{{ $t("basedata.username") }}</b-col>
      <b-col sm="9">{{ currentUser.username }}</b-col>
    </b-row>
    <b-row>
      <b-col sm="3">Auth-Token</b-col>
      <b-col sm="9">
        {{ currentUser.access.substring(0, 10) }} ...
        {{ currentUser.access.substr(currentUser.access.length - 10) }}
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="3">{{ $t("basedata.email") }}</b-col>
      <b-col sm="9">{{ currentUser.email }}</b-col>
    </b-row>
    <b-row>
      <b-col sm="3">{{ $t("basedata.active") }}</b-col>
      <b-col sm="9">
        <b-icon-check v-if="currentUser.is_active" />
        <b-icon-x v-else />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="3">{{ $t("basedata.staff") }}</b-col>
      <b-col sm="9">
        <b-icon-check v-if="currentUser.is_staff" />
        <b-icon-x v-else />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="3">{{ $t("basedata.superuser") }}</b-col>
      <b-col sm="9">
        <b-icon-check v-if="currentUser.is_superuser" />
        <b-icon-x v-else />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
/* eslint-disable */
export default {
  name: "profile",
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>
